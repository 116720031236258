$nav-height: 45px;
$header-height: 100px;
$spacer-height: $header-height + $nav-height;

@import "mixins";
@import "splash";
@import "header";
@import "footer";
@import "nav";
@import "home";
@import "features";
@import "contact";



.knowtifi-img {
  border-right: solid 8px $red;
  border-bottom: solid 8px $red;
  border-style: groove;
}

.text-up {
  @include rem-size(1.1);
}

.do-you-know {
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.news-div {
  margin: 50px 0;
}
.news-article-div {
  margin: 50px 0;
  img {
    max-width: 100%;
  }
  .text-up {
    color: $gray-600;
  }
  span.date {
    display: block;
    color: $red;
    @include rem-size(0.85);
    margin-bottom: 8px;
  }
}
.news-item {
  margin-bottom: 40px;
  img {
    max-width: 100%;
    margin-bottom: 6px;
    @include transition(all 0.3s ease);
  }
  .news-image {
    @extend .background-cover;
    img {
      max-width: 100%;
    }
  }
  a {
    color: $black;
    text-decoration: none;
    font-weight: 500;
    @include transition(all 0.3s ease);

    b {
      color: $gray-600;
      font-weight: 400;
      @include rem-size(0.9);
    }

    &:hover {
      color: $red;

      img {
        transform: scale(1.05);
      }
    }
  }
}

.news-grid-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.loading-div {
  margin: auto;
  @include rem-size(4);
  color: $gray-700;
}


main {
  padding: 0;
  min-height: 500px;
}

.testimonials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 75px;

  img {
    max-width: 150px;
    border-radius: 75px;
    margin-right:40px;
  }

  @media (max-width: 767px) {
    display: block;
    text-align: center;

    img {
      max-width: 100px;
      margin: 0 0 20px;
    }
  }
}
.dots {
  text-align: center;
  font-size: 11px;
  margin-bottom: 20px;
}





.bot-10 {
  margin-bottom: 10px;
}
.k     span {
  @include rem-size(1.25);
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 14px;
}



.block {
  padding: 50px 20px;
}

.bg-white {
  background-color: $white;
}
.bg-gray {
  background-color: #F0F1F3;
}
.bg-red {
  background-color: $red;
  * {
    color: $white !important;
  }
}
.red {
  color: $red;
}
.white {
  color: $white;
}
.green {
  color: $green;
}
.mint {
  color: $mint;
}

h1, h2, h3, h4, h5 {
  font-family: "Maven Pro", sans-serif;
  font-weight: 600;
  letter-spacing: -0.05em;

  &.upper {
    text-transform: uppercase;
    font-weight: 800;
    line-height: 0.95em;
  }

}

.split50 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;

  & > div:first-child {
    width: 45%;
  }

  & > div.sep {
    width: 10%;
    text-align: center;
    //background: url("../webp/sep.webp") center center no-repeat;
    height: 300px;
  }

  & > div:last-child {
    width: 45%;
  }

  .sep {
    flex: 1;
    width: 2px;
    background-color: $body-color;
    height: auto;
  }
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.flex-row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}


.columns-2 {
  column-count: 2;
  column-gap: 50px;

  p {
    break-inside: avoid;
  }

  @media (max-width:991px) {
    column-count: 1;
    column-gap: 0;
  }
}



@font-face { font-family: "Maven Pro"; font-display: swap; }



.text-section {
  padding-bottom: 40px;
  border-bottom: solid 1px $gray-300;
  margin-bottom: 40px;
}

.whats-happening {
  @media (max-width:767px) {
    padding-bottom: 40px;
    border-bottom: solid 1px $gray-300;
    margin-bottom: 40px;
  }

}

.minty {
  background-color: $ltgreen;
}

.border-top {
  border-top: solid 1px $gray-100;
}

.section-padding {
  padding: 70px 0;
}

div.up {
  @include rem-size(1.2);
}

.k {
  display: inline-block;
  background: $red;
  color: $white;
  padding: 0 10px;
  border-radius: 4px;
}


.dlbuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.small {
    justify-content: flex-end;
    transform: scale(0.7);

    .dlbutton {
      background-color: #F3F3F3;
      &:hover {
        background: $dkblue;
      }
    }
  }
}



.dlbutton {
  background: $white;
  padding: 10px 30px;
  color: $dkblue !important;
  margin: 20px;
  border: 0;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 240px;
  height: 70px;
  @include transition( all 0.3s ease);

  i, svg {
    color: $dkblue !important;
    @include rem-size(3);
    @include transition( all 0.3s ease);
    margin-right: 20px;

    &.fa-google-play {
      @include rem-size(2.5);
    }
  }

  div {
    color: $dkblue !important;
    @include transition( all 0.3s ease);
    b {
      display: block;
      color: $dkblue !important;
      @include transition( all 0.3s ease);
    }
  }

  &:hover {
    background: $dkblue;

    i, svg {
      color: $white !important;
    }

    div {
      color: $white !important;
      b {
        display: block;
        color: $white !important;

      }
    }
  }

}

.big-btn {
  margin-top: 20px;
  display: block;
  background: $red;
  color: $white;
  @include rem-size(1.25);
  text-align: center;
  padding: 12px 0;
  border: 0;
  border-radius: 8px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: mix($red, $white, 35%);
  }
}

p.pricing {
  @include rem-size(1.5);
  text-align: center;
}
.strike {
  color: $red;
  text-decoration: line-through;
  @include rem-size(1.5);
}
.discount {
  font-weight: bold;
  color: $green;
  @include rem-size(2);
}

.btn.btn-danger,
.btn.btn-success {
  color: $white !important;
}

.text-right {
  text-align: right;
}

td.highlight, {
  background: mix($red,$white,10%);
  border: solid 2px $red;
  border-width: 0 2px;
  border-bottom: solid 1px mix($red,$white,50%);

  &.end {
    border-bottom: solid 2px $red;

  }
}
th.highlight, {
  background: mix($red,$white,10%);
  border: solid 2px $red;
  border-width: 2px 2px 0 2px;
}

.social {
  @include rem-size(1.25);

  a {
    i, svg {
      background-color: $dkblue;
      padding: 10px;
      border-radius: 10px;
      width: 40px;
      text-align: center;
      color: $white;
      @include transition(all 0.3s ease);
    }

    &:hover i, svg {
      background-color: $red;
    }
  }



}

h4.underlined {
  color: $dkblue;
  border-bottom: solid 1px $gray-600;
}

.form-group.horz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .form-control {
    flex: 1;
    margin-right: 10px;
  }
  button {

  }
}

.text-down {
  @include rem-size( 0.85 );
}


header img.dlbtn {
  text-align: right;
  margin-left: 20px;
  max-width: 150px;
  position: relative;
  top: 4px;
  @include transition(all 0.3s ease);

  &:hover {
    top: 0;
  }
}

.dl-cta img.dlbtn {
  text-align: center;
  margin: 10px 20px;
  max-width: 200px;
  position: relative;
  top: 4px;
  @include transition(all 0.3s ease);

  &:hover {
    top: 0;
  }
}


.faq-categories {
  border-bottom: solid 2px $gray-500;
  margin-bottom: 40px;

  .faq-cat-tab {
    display: inline-block;
    padding: 10px 30px;
    color: $gray-500;
    border-top: solid 2px transparent;
    border-left: solid 2px transparent;
    border-right: solid 2px transparent;
    position: relative;
    top: 2px;
    cursor: pointer;

    &.on {
      background-color: $white;
      border-top: solid 2px $gray-500;
      border-left: solid 2px $gray-500;
      border-right: solid 2px $gray-500;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: $red;
      font-weight: bold;
    }

  }
}

.faq-list {
  color: $red;
  @include rem-size(1.3);

  li {
    padding-left: 8px;
  }

  h5 {
    color: $black;
    @include rem-size(1.3);
  }
  div {
    color: $black;
    @include rem-size(1);
  }
}

.download-now {
  color: $red;
  cursor: pointer;
  position: relative;
  top: 25px;

  i {
    margin-right: 8px;
    font-size: 1.2em;
    position: relative;
    top: 3px;
  }

  a, a:hover {
    text-decoration: none;
  }
}

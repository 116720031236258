header {
  background-color: $white;
  height: $header-height;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow: visible;

  #logo {
    max-height: 50px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;
    }
  }

  #header-buttons {
    width: 300px;
    @include flex(row, space-between, center);
    a {
      display: block;
      max-width: 48%;
      img { max-width: 100%; }
    }
  }
  #header-buttons-small {
    @include flex(row, flex-end, center);
    display: none;
    a {
      display: inline-block;
      max-width: 45px;
      margin: 2px;
      img { max-width: 100%; }
    }
    i {
      @include rem-size(2);
      margin-left: 8px;
      position: relative;
      top: 7px;
      @include dotransition(all 0.3s ease);

      &.fa-bars {
        color: $dkblue;
      }
      &.fa-xmark-large {
        color: $red;
      }
      &.fa-xmark {
        color: $red;
      }
    }
  }

  .content {
    @include flex();
    height: $header-height;
  }
  
  @media (max-width: 400px) {
    .col-12 {
      padding: 0 20px !important;
    }
  }
}

.spacer {
  height: $spacer-height;
}

@media (max-width: 700px) {
  header {
    #logo {
      max-height: 40px;
    }
    #header-buttons {
      width: 275px;
    }
  }
}

@media (max-width: 600px) {
  header {
    #logo {
      max-width: 250px;
    }
    #header-buttons {
      width: 225px;
    }
  }
}

@media (max-width: 630px) {
  header {
    height: 85px;

    .content {
      height: 85px;
    }

    #logo {
      max-width: 200px;
      max-height: unset;
    }
    #header-buttons {
      display: none;
    }
    #header-buttons-small {
      display: block;
    }
  }
  .spacer {
    height: 85px;
  }
}

@media (max-width: 450px) {
  header {
    #logo {
      max-width: 160px;
      max-height: unset;
    }
    #header-buttons {
      display: none;
    }
    #header-buttons-small {
      display: block;
      a {
        display: inline-block;
        max-width: 36px;
        margin: 2px;
        img { max-width: 100%; }
      }
      i {
        @include rem-size(1.6);
        margin-left: 8px;
        position: relative;
        top: 5px;
      }
    }
  }
}
